<template>
  <div>
    <div class="echars-box">
      <div id="myChart"></div>
    </div>

  </div>
</template>

<script>
var echarts = require('echarts');
export default {
  props: {
    light:{

    }
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = echarts.init(document.getElementById('myChart'))
      // 绘制图表
      myChart.setOption({
        // title: {
        //   text: '未来一周气温变化',
        //   subtext: '纯属虚构'
        // },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['光照度']
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              yAxisIndex: 'none'
            },
            dataView: {readOnly: false},
            magicType: {type: ['line', 'bar']},
            restore: {},
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['6个小时前', '12个小时前', '18个小时前', '24个小时前', '30个小时前', '36个小时前', '42个小时前','48个小时前', '54个小时前', '60个小时前', '66个小时前', '72个小时前', ].reverse(),
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} klux'
          }
        },
        series: [
          {
            name: '光照度',
            type: 'line',
            data: this.light,
            markPoint: {
              data: [
                {type: 'max', name: '最大值'},
                {type: 'min', name: '最小值'}
              ]
            },
            markLine: {
              data: [
                {type: 'average', name: '平均值'}
              ]
            }
          },

        ]
      });
    }
  }
}
</script>

<style scoped>
.echars-box {
  height: 620px;
  background: #FFFFFF;
}

#myChart {
  width: 1200px;
  height: 620px;
  padding-top: 20px;
}

</style>