<template>
  <div id="contorlBox">
    <div class="control-box">
      <el-row class="control-box-row" v-for="(item,index) in deviceList" :key="index">
        <el-col :span="4" style="text-align: center;margin-top: 30px">
          <img :src="item.img" width="82" height="82" alt=""> <br>
          {{ item.name }}
        </el-col>
        <el-col v-if="item.code === '00'" :span="19" style="height: 65px">
          <el-col v-for="(device , index) in item.equiList" :key="index">
            <el-col :span="5">
              <el-col class="control-box-name">
                <el-col class="control-box-name-title">{{ device.name }}</el-col>
                <el-col class="control-box-name-data" v-if="device.type !==2">当前状态：{{ device.statusName }}</el-col>
              </el-col>
            </el-col>
            <el-col :span="4">
              <el-col class="control-box-name2" style="margin-top: 20px"
                      @click.native="showModelVideo(device.tbVideos.url,device.tbVideos.accessToken)">{{ device.tbVideos ? device.tbVideos.name : '暂无监控' }}
              </el-col>
            </el-col>
            <el-col :span="24" style="margin-top: -80px">
              <el-collapse v-model="activeNames" style="margin-top: 20px" @change="handleChange" accordion>
                <el-collapse-item title="展开控制设备" name="1">
                  <el-col  :span="24">
                    <el-col :span="8" v-for="(itm,index) in device.shuifeiEquiEntityList" :key="index">
                      <el-col class="control-box-name3">
                        {{ itm.shuifeiName }} <br>
                        <el-radio-group v-model="itm.status"
                                        @change="changeSWitch2($event,device.gId,device.id,itm.id,itm.status)"
                                        style="margin-top: 3px">
                          <el-radio :label="1" border size="medium" text-color="#006837" fill="#006837">开启</el-radio>
                          <el-radio :label="0" border size="medium" text-color="#006837" fill="#006837">关闭</el-radio>
                        </el-radio-group>
                      </el-col>
                    </el-col>
                  </el-col>
                </el-collapse-item>
              </el-collapse>
            </el-col>
          </el-col>
        </el-col>
        <el-col v-for="(device , index) in item.equiList" :key="index" :span="19" style="height: 65px" v-else>
          <el-col :span="5">
            <el-col class="control-box-name">
              <el-col class="control-box-name-title">{{ device.name }}</el-col>
              <el-col class="control-box-name-data" v-if="device.type !==2">当前状态：{{ device.statusName }}</el-col>
            </el-col>
          </el-col>
          <el-col :span="4">
            <el-col class="control-box-name2" style="margin-top: 20px"
                    @click.native="showModelVideo(device.tbVideos.url,device.tbVideos.accessToken)">{{ device.tbVideos ? device.tbVideos.name : '暂无监控' }}
            </el-col>
          </el-col>
          <el-col :span="12" :offset="3" v-if="device.type ==2">
            <el-col class="control-box-name3">
              <el-radio-group v-model="device.frontSide" @change="changeRadio($event,device.gId,device.id)">
                <el-radio :label="1" border size="medium" text-color="#006837" fill="#006837">正转</el-radio>
                <el-radio :label="3" border size="medium" text-color="#006837" fill="#006837">反转</el-radio>
                <el-radio :label="2" border size="medium" text-color="#006837" fill="#006837">停止</el-radio>
              </el-radio-group>
            </el-col>
          </el-col>
          <el-col :span="8" :offset="3" v-else>
            <el-col class="control-box-name3">
              <el-radio-group v-model="device.status" @change="changeSWitch($event,device.gId,device.id)">
                <el-radio :label="1" border size="medium" text-color="#006837" fill="#006837">开启</el-radio>
                <el-radio :label="2" border size="medium" text-color="#006837" fill="#006837">关闭</el-radio>
              </el-radio-group>
            </el-col>

          </el-col>
          <!--          <el-col :span="3" :offset="1" v-if="device.type !==2">-->
          <!--            <el-col class="control-box-name2" style="margin-top: 15px">-->
          <!--              <el-switch-->
          <!--                  v-model="device.status"-->
          <!--                  active-color="#13ce66"-->
          <!--                  :active-value="1"-->
          <!--                  :inactive-value="0"-->
          <!--                  @change="changeSWitch($event,device.gId,device.id)"-->
          <!--                  inactive-color="#999999">-->
          <!--              </el-switch>-->
          <!--            </el-col>-->
          <!--          </el-col>-->
        </el-col>

      </el-row>
    </div>
    <el-dialog
        title="视频播放"
        :visible.sync="dialogVisible"
        width="700px"
        style="text-align: center"
        v-if='dialogVisible'
    >
      <e-z-u-i-kit-js :url="videoUrl" :accessToken="videoToken"></e-z-u-i-kit-js>
      <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
        <!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
  </span>
    </el-dialog>
  </div>
  <!--      <video id="myPlayer" poster="[这里可以填入封面图片URL]" controls playsInline webkit-playsinline [autoplay]>-->
  <!--        <source src="https://open.ys7.com/ezopen/h5/rec?accessToken=ra.bt5rkpir68sj3c920wyz8eb90uy7mq1s-1otxvx6xmx-14gm9pv-qsy1f9pdz&deviceSerial=203751922&channelNo=1&recType=1" type=""/>-->
  <!--      </video>-->
  <!--    </div>-->
  <!--  </div>-->
</template>

<script>
import '../../../static/ezuikit.js'
import EZUIKitJs from '@/components/EZUIKitJs.vue'
// var player = new EZUIPlayer('myPlayer');

export default {
  name: "controlBox",
  props: {
    deviceList: {
      type: Array,
      defalut: []
    }
  },
  data() {
    return {
      radio1: 0,
      radio2: '1',
      videoUrl:'',
      videoToken:'',
      activeNames: [],
      value1: true,
      value2: true,
      dialogVisible: false
    }
  },
  components:{
    EZUIKitJs
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    showModelVideo(url,token){
      console.log('showModelVideo',url,token)
      if(url||token){
        this.dialogVisible = true
        this.videoUrl = url
        this.videoToken = token
      }


    },
    changeRadio(event, gid, id) {
      console.log(event, gid, id,)
      let params = {
        "frontSide": event,
        "gId": gid,
        "id": id,
      }
      this.$api.post('equi/editMonitor', params, res => {
        if (res.code === 1) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning',
          });

        }
        this.$parent.getDeviceList();
        console.log(res)
      })
    },
    changeSWitch(event, gid, id) {
      console.log(event, gid, id,)
      let params = {
        "status": event,
        "gId": gid,
        "id": id,
      }
      this.$api.post('equi/editMonitor', params, res => {
        console.log(res, '是是是')
        if (res.code === 1) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning',
          });

        }
        this.$parent.getDeviceList();
        console.log(res)
      })
    } ,
    changeSWitch2(event, gid, id,shuifeiEquiId,shuifeiEquiStatus) {
      console.log(event, gid, id,shuifeiEquiId,shuifeiEquiStatus)
      let params = {
        "status": event,
        "gId": gid,
        "id": id,
        'shuifeiEquiId':shuifeiEquiId,
        'shuifeiEquiStatus':shuifeiEquiStatus
      }
      this.$api.post('equi/editMonitor', params, res => {
        console.log(res, '是是是')
        if (res.code === 1) {
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning',
          });
          this.$parent.getDeviceList();
        }
        console.log(res)
      })
    }
  }

}
</script>

<style>
.control-box {
  min-height: 510px;
  /*margin-bottom: 30px;*/
  margin-top: 30px;
  background: #FFFFFF;
}

.control-box-row {
  height: 169px;
  border-bottom: 1px solid #E8E8E8;
  padding-top: 10px;

}

#contorlBox .el-collapse-item__header {
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: #FFF;
  color: #303133;
  cursor: pointer;
  border-bottom: 0;
  font-size: 18px;
  font-weight: 500;
  margin-left: 475px;
  transition: border-bottom-color .3s;
  outline: 0;
}

#contorlBox .el-collapse {
  border-top: 0;
  border-bottom: 0;
}

#contorlBox .el-collapse-item__arrow {
  margin: 0 8px 0 10px;
  transition: transform .3s;
  font-weight: 300;
}
.control-box-name {
  /*height: 85px;*/
  margin-top: 20px;
}

.control-box-name2 {
  line-height: 60px;
  cursor: pointer;
}

.control-box-name3 {
  margin-top: 30px;
}

.control-box-name-title {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #000000;
}

.control-box-name-data {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin-top: 9px;

}


</style>
