<template>
  <div>
    <div :class=" is_abnormal=== true ? 'head-title-box_abnormal' :'head-title-box'">
      <el-row>
        <el-col :span="2" class="head-title-con-left">
          <el-col class="head-title-con-type">种植作物</el-col>
          <el-col class="head-title-con-name">{{ detailList.crop }}</el-col>
        </el-col>
        <el-col :span="2" class="head-title-con-left">
          <el-col class="head-title-con-type">种植面积</el-col>
          <el-col class="head-title-con-name">{{ detailList.area }}㎡</el-col>
        </el-col>

        <el-col :span="2" :offset="4" class="head-title-con" @click.native="shangButton">
          <i class="el-icon-arrow-left"></i>
        </el-col>
        <el-col :span="4" class="head-title-con" style="overflow: hidden">
          {{ greenName }}
        </el-col>
        <el-col :span="2" class="head-title-con" @click.native="xiaButton">
          <i class="el-icon-arrow-right"></i>
        </el-col>
        <el-col :span="2" :offset="4" class="head-title-con" @click.native="toHistroy">
          历史数据
        </el-col>
        <!--        <el-col :span="4">-->
        <!--          <el-select v-model="value" filterable placeholder="请选择">-->
        <!--            <el-option-->
        <!--                v-for="item in options"-->
        <!--                :key="item.value"-->
        <!--                :label="item.label"-->
        <!--                :value="item.value">-->
        <!--            </el-option>-->
        <!--          </el-select>-->
        <!--        </el-col>-->
        <el-col :span="2" class="head-title-con" @click.native="toVideo">
          视频监控
        </el-col>
      </el-row>
      <el-row style="padding:  0 10px;">
        <el-col :span="4" :class="selectbox==='box1' ? 'head-title-con-box_actice' : 'head-title-con-box' "
                @click.native="selectChecked('box1')">
          <el-col style="margin-top: 40px;height: 60px">
            <img src="../../assets/greenhouse/1.png" alt="" width="45" height="60">
          </el-col>
          <el-col :span="10" :offset="2" class="head-title-con-type2">空气温度</el-col>
          <el-col :span="10" class="head-title-con-type2">土壤温度</el-col>
          <el-col :span="10" :offset="2" class="head-title-con-data">{{ detailList.temper }}℃</el-col>
          <el-col :span="10" class="head-title-con-data">{{ detailList.landTemper }}℃</el-col>
        </el-col>
        <el-col :span="4" :class="selectbox==='box2' ? 'head-title-con-box_actice' : 'head-title-con-box' "
                @click.native="selectChecked('box2')">
          <el-col style="margin-top: 41px;height: 59px">
            <img src="../../assets/greenhouse/2.png" alt="" width="60" height="58">
          </el-col>
          <el-col :span="10" :offset="2" class="head-title-con-type2">空气湿度</el-col>
          <el-col :span="10" class="head-title-con-type2">土壤湿度</el-col>
          <el-col :span="10" :offset="2" class="head-title-con-data">{{ detailList.humid }}%</el-col>
          <el-col :span="10" class="head-title-con-data">{{ detailList.landHumid }}%</el-col>
        </el-col>
        <el-col :span="4" :class="selectbox==='box3' ? 'head-title-con-box_actice' : 'head-title-con-box' "
                @click.native="selectChecked('box3')">
          <el-col style="margin-top: 49px;height: 51px">
            <img src="../../assets/greenhouse/3.png" alt="" width="60" height="42">
          </el-col>
          <el-col :span="24" class="head-title-con-type2">二氧化碳浓度</el-col>
          <el-col :span="24" class="head-title-con-data">{{ detailList.co2 }}PPM</el-col>
        </el-col>
        <el-col :span="4" :class="selectbox==='box4' ? 'head-title-con-box_actice' : 'head-title-con-box' "
                @click.native="selectChecked('box4')">
          <el-col style="margin-top: 40px;height: 60px">
            <img src="../../assets/greenhouse/4.png" alt="" width="60" height="60">
          </el-col>
          <el-col :span="24" class="head-title-con-type2">光照度</el-col>
          <el-col :span="24" class="head-title-con-data">{{ detailList.light }}Klux</el-col>
        </el-col>
        <el-col :span="4" :class="selectbox==='box5' ? 'head-title-con-box_actice' : 'head-title-con-box' "
                @click.native="selectChecked('box5')">
          <el-col style="margin-top: 40px;height: 60px">
            <img src="../../assets/greenhouse/5.png" alt="" width="60" height="60">
          </el-col>
          <el-col :span="24" class="head-title-con-type2">土壤PH值</el-col>
          <el-col :span="24" class="head-title-con-data">{{ detailList.landPH }}</el-col>
        </el-col>
        <el-col :span="4" :class="selectbox==='box6' ? 'head-title-con-box_actice' : 'head-title-con-box' "
                @click.native="selectChecked('box6')">
          <el-col style="margin-top: 40px;height: 60px">
            <img src="../../assets/greenhouse/6.png" alt="" width="60" height="60">
          </el-col>
          <el-col :span="24" class="head-title-con-type2">土壤导电率</el-col>
          <el-col :span="24" class="head-title-con-data">{{ detailList.landElec }}s/m</el-col>
        </el-col>
      </el-row>
      <el-col v-if="is_abnormal" style="margin-top: 10px">当前二氧化碳浓度224ppm,低于设定最小值300ppm,建议采取措施提升二氧化碳浓度</el-col>
    </div>
    <div v-if="selectbox === 'box1'">
      <tem :temper="temper" :landTemper="landTemper"></tem>
    </div>
    <div v-if="selectbox === 'box2'">
      <hum :humid="humid" :landHumid="landHumid"></hum>
    </div>
    <div v-if="selectbox === 'box3'">
      <co2 :co2="co2"></co2>
    </div>
    <div v-if="selectbox === 'box4'">
      <light :light="light"></light>
    </div>
    <div v-if="selectbox === 'box5'">
      <land-p-h :landPH="landPH"></land-p-h>
    </div>
    <div v-if="selectbox === 'box6'">
      <land-elec :landElec="landElec"></land-elec>
    </div>

    <div>
      <controlBox :deviceList="deviceList"></controlBox>
    </div>
  </div>
</template>

<script>
import tem from '@/components/canopyManageMent/echars/tem'
import co2 from "@/components/canopyManageMent/echars/co2";
import hum from "@/components/canopyManageMent/echars/hum";
import landElec from "@/components/canopyManageMent/echars/landElec";
import landPH from "@/components/canopyManageMent/echars/landPH";
import light from "@/components/canopyManageMent/echars/light";

import controlBox from '@/components/canopyManageMent/controlBox'

export default {
  name: "CanopyManagement",
  components: {
    tem, controlBox,
    co2,
    hum,
    landElec,
    landPH,
    light
  },
  data() {
    return {
      selectbox: 'box6',
      is_abnormal: '', //是否存在异常
      bId: '', //大棚编号
      detailList: {},
      deviceList: [],
      temper: [],
      landTemper: [],
      humid: [],
      landHumid: [],
      co2: [],
      light: [],
      landPH: [],
      landElec: [],
      greenHouseList: [],
      options: [],
      value: '',
      greenName: ''
    }
  },

  mounted() {
    this.getDeviceList()
    this.getDataList()
    console.log(this.$route.query.id, '123', localStorage.getItem('detailId'))
    this.bId = this.$route.query.id || localStorage.getItem('detailId')
    console.log(this.bId,'bId')

    let params = {
      gid: this.$route.query.id || localStorage.getItem('detailId')
    }
    this.$api.post('gHouse/getByBid', {}, res => {
      // console.log(res)
      if (res.data){
        this.greenHouseList = res.data
        let idx = res.data.findIndex((itm) => itm.id == this.bId)
        this.greenName = res.data[idx].name
      }
    })
    this.$api.post('data/getDef', params, res => {
      // console.log(res, '我曾')
      if (res.code === 0) {
        for (let i = 0; i < res.data.length; i++) {
          this.temper.push(res.data[i].temper)
          this.landTemper.push(res.data[i].landTemper)
          this.humid.push(res.data[i].humid)
          this.landHumid.push(res.data[i].landHumid)
          this.co2.push(res.data[i].co2)
          this.light.push(res.data[i].light)
          this.landPH.push(res.data[i].landPH)
          this.landElec.push(res.data[i].landElec)
          this.selectbox ='box1'
        }
      }
    })
  },
  methods: {
    getDeviceList(){
      let dataGid = {
        gid: this.$route.query.id || localStorage.getItem('detailId')
      }
      this.$api.post('data/getListEqui', dataGid, res => {
        // console.log(res)
        if (res.data){
          this.deviceList = res.data
        }
      })
    },
    getDataList(){
      let params = {
        gid: this.$route.query.id || localStorage.getItem('detailId')
      }
      this.$api.post('data/getNew', params, res => {
        // console.log(res)
        if (res.data){
          this.detailList = res.data
        }
      })
    },
    selectChecked(data) {
      this.selectbox = data
    },
    toHistroy() {
      this.$router.push({
        name: 'CanopyManagementDis',
        query: {
          id: this.bId
        }
      })
    },
    toVideo() {
      this.$router.push({
        name: 'videoList',
        query: {
          id: this.bId
        }
      })
    },
    xiaButton() {
      var idx1 = this.greenHouseList.findIndex((item) =>
          item.id == this.bId
      )
      console.log(this.greenHouseList.length,'this.greenHouseList.length',idx1)
      if (!this.greenHouseList[idx1 + 1]) {
        console.log('最后一个')
      } else {
        console.log(idx1, '列表第几个greenHouseList', this.greenHouseList[idx1 + 1])
        localStorage.setItem("detailId", this.greenHouseList[idx1 + 1].id)
        this.$router.replace({
          name: "middlePage",
          query: {
            id: this.greenHouseList[idx1 + 1].id
          }
        })
      }
    },
    shangButton() {
      var idxa = this.greenHouseList.findIndex((item) =>
          item.id == this.bId
      )
      console.log(idxa,'wwwwidxa')
      if (idxa <= 0) {
        console.log('第一个')
      } else {
        console.log(idxa, '列表第几个greenHouseList', this.greenHouseList)
        localStorage.setItem("detailId", this.greenHouseList[idxa - 1].id)
        this.$router.replace({
          name: "middlePage",
          query: {
            id: this.greenHouseList[idxa - 1].id
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.head-title-box {
  height: 300px;
  background: #FFFFFF;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.head-title-box_abnormal {
  height: 340px;
  background: #FFFFFF;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 30px;
}

.head-title-con-name {
  margin-top: 9px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;

}

.head-title-con-type {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}


.head-title-con-left {
  height: 76px;
  margin-top: 10px;
}

.head-title-con {
  height: 76px;
  line-height: 76px;
  cursor: pointer;
}

.head-title-con-box {
  height: 200px;
  cursor: pointer;
  padding: 0 5px;
}

.head-title-con-box_actice {
  height: 200px;
  background: #CBE9CB;
  cursor: pointer;
  padding: 0 5px;
}

/*.head-title-con-img {*/
/*  height: 80px;*/
/*}*/


.head-title-con-type2 {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  margin-top: 25px;

}

.head-title-con-data {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #0090FF;
  margin-top: 6px;
}


</style>
